export default [
    
    {
        id: 1,
        type: "School",
        title: "Breakout",
        description: "A recreation of the arcade game Breakout" ,
        image: "Breakout2.png",
    },
    {
        id: 2,
        type: "School",
        title: "Galaga",
        description: "A recreation of the arcade game Galaga" ,
        image: "Galaga2.png",
    },
    {
        id: 3,
        type: "School",
        title: "Drone Simulation",
        description: "A recreation of the arcade game Galaga" ,
        image: "Drones.png",
    },
    {
        id: 4,
        type: "School",
        title: 'Linear Algebra Algorithms',
        description: "A series of assignments applying theory to practice" ,
        image: "LinAlg.png",
    },
    {
        id: 5,
        type: "Personal",
        title: "Secret Marshal",
        description: "A recreation of the board game Secret Hitler in VRChat/Unity" ,
        image: "SecretMarshal2.png",
    },
    {
        id: 6,
        type: "Personal",
        title: "Algorithm Visualiser",
        description: "An Algorithm Visualiser similar to the ones on YouTube" ,
        image: "AlgorithmVisualiser.png",
    },
    {
        id: 7,
        type: "Personal",
        title: "Calculator",
        description: "A calculator inspired by the Windows Calculator" ,
        image: "Calculator.png",
    },
    /*
     */
    {
        id: 8,
        type: "Personal",
        title: 'Website',
        description: "A website to store and show my portfolio in detail" ,
        image: "ThisWebsite.png",
    }
]