import React from 'react'
import HeroSectionPortfolio from './HeroSectionPortfolio'

export default function Portfolios() {
  return (
    <>
      <HeroSectionPortfolio />
    </>
  )
}
