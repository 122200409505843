import React from 'react'
import './HeroSection.css';

export default function HeroSection() {
  return (
    <div className='hero-container'>
        <h1>
        I'm ZacRyl
        </h1>
        <h2>Computer Science Student <br /> Copenhagen University</h2>
        <h3>About me</h3>
    
        <p class="textbox">I am a 24 years old out of Copenhagen Denmark.<br />
        My first name is Adam and im studying Computer Science at Copenhagen University.<br />
        <br />
        I am dated to graduate with a Masters in 2026 with a passion for backend development.</p>
    </div>
    
  )
}
