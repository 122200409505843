import React from 'react';
import './HeroSectionContact.css';

export default function HeroSection() {
  return (
    <div className='hero-container-Contacts'>
      <h1>Contacts</h1>
      <h2>
        My preferred methods of contact are email or a LinkedIn message. My LinkedIn is open for anyone, so feel free to add me.
      </h2>
      <div className='hero-container-Images'>
        <a href="https://github.com/Zacryl" target="_blank" rel="noopener noreferrer">
          <img className="card-image-contacts" src={`/images/GitHubLogo.png`} alt={'GitHub'} />
        </a>
        <a href="mailto: zacryldev@gmail.com" target="_blank" rel="noopener noreferrer">
          <img className="card-image-contacts" src={`/images/GmailLogo.png`} alt={'Gmail'} />
        </a>
        <a href="https://www.linkedin.com/in/adam-zachariassen-rylander-1120a9184/" target="_blank" rel="noopener noreferrer">
          <img className="card-image-contacts" src={`/images/LinkedInLogo2.png`} alt={'LinkedIn'} />
        </a>
      </div>
    </div>
  );
}
