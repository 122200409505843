import React from 'react';
import { Link, Router } from 'react-router-dom';
import './Navbar.css';
import './Pages/HomePage/Home'
import './Pages/PortfoliosPage/Portfolios'

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className='Home'>Home</Link>
        <Link to="/portfolio" className='Portfolios'>Portfolio</Link>
        <Link to="/contact" className='Contact'>Contact</Link>
      </div>
    </nav>
  );
}

export default Navbar;
